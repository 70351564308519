.privacy-policy-button-container{
    padding-top: 50px;
}
.back-button-text{
    font-family: 'Nunito';
font-style: normal;
font-weight: 600;
font-size: 17px;
line-height: 22px;
display: flex;
align-items: flex-end;
text-align: center;
color: #3A3A3A;
}
.privacy-policy-heading-container p{   
margin: auto;
font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 60px;
line-height: 72px;
text-align: center;
color: #393939;
}
.privacy-policy-paragraph-container{
    padding-top: 20px;
}
.privacy-policy-paragraph-container p{
    margin: auto;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    
    color: #393939;
}
.pricavy-policy-text-container{
    padding-left: 130px;
    padding-top: 70px;
    padding-right: 130px;
}
.pricavy-policy-text-container ol {
    margin: auto;
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 22px;
line-height: 34px;
text-align: center;
color: #000000;
}