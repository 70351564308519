.properties-main-image-container {
  background: url("/public/icons/properties.jpg");
  width: 100%;
  height: 700px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
}
.pagination-alignment {
  margin-top: 50px;
}

.Top-left {
  display: flex;
  flex-direction: column;
}
.contact-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 36px 26px;
  gap: 17px;
  width: 370px;
  height: 334px;
  margin-top: 200px;
  margin-left: 30px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.input-styling {
  width: 318px;
  height: 54px;
  left: 0px;
  top: 0px;

  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}
.heading-contact {
  width: 318px;
  height: 22px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  /* identical to box height, or 92% */

  display: flex;
  align-items: flex-end;
  text-align: center;
  text-transform: uppercase;

  color: #3a3a3a;
}
.contact-box-content {
  width: 318px;
  height: 92px;
  top: 66px;

  background: #ffffff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
}
.contact-button {
  width: 318px;
  height: 50px;

  background: #e5be54;
  border-radius: 5px;
}
.button-text {
  width: 58px;
  height: 22px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 138% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #ffffff;
}
.agent-div {
  position: relative;
  width: 120px;
  height: 100%;
}
.agent-text {
  width: 100%;
  height: 80px;
  padding-left: 26px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #3a3a3a;
}
.agent-image {
  width: 83px;
  height: 84px;
  /* margin-top: 86px; */
  margin-left: 26px;
  position: absolute;
}
.agent-box {
  display: flex;
  flex-direction: column;
  width: 420px;
  height: 218px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  margin-left: 30px;
  margin-top: 18px;
}
.agent-details {
  width: 100%;
  height: 84px;
  display: flex;
}
.s-description {
  width: 770px;
  margin-top: 20px;
}

.font-style {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  /* or 133% */

  display: flex;
  align-items: flex-end;

  color: #3a3a3a;
}
.price-detail {
  width: 221px;
  height: 22px;

  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 22px;
  /* identical to box height, or 92% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #3a3a3a;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 15px;
}
.location-detail {
  width: 315px;
  height: 22px;

  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #3a3a3a;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 15px;
}
.details-type {
  width: 200px;
  height: 22px;

  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height, or 122% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #3a3a3a;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-left: 15px;
}
.house-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 50px 24px 49px;
  gap: 50px;

  width: 570px;
  height: 124px;

  background: #ffffff;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.12);
  border-radius: 10px;
}
.box-text {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 32px;
  /* identical to box height, or 145% */

  display: flex;
  align-items: flex-end;

  color: #3a3a3a;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 10px;
}
.box-div {
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.back-screen {
  width: 53px;
  height: 22px;
  margin-bottom: 10px;

  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 22px;
  /* identical to box height, or 92% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #3a3a3a;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 154px;
}
.ant-card-body {
  padding: 0px !important;
}
.lower-images {
  width: 170px;
  height: 96px;
  border-radius: 6px;
}
.description {
  width: 1325px;
}
.agent-name {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  /* identical to box height, or 100% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #3a3a3a;

  padding-left: 20px;
  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 0;
}
.listing-agent-details {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #3a3a3a;

  /* Inside auto layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  padding-left: 10px;
}
.description-section {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 32px;
  /* identical to box height, or 123% */

  display: flex;
  align-items: flex-end;

  color: #3a3a3a;
  margin-bottom: 30px;
}
