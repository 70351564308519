.footer-divider-container {
  padding-top: 101px;
  padding-left: 10%;
  padding-right: 10%;
}
.footer-main-container {
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
}

.footer-listing-alignment {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
}
.footer-logo {
  display: flex;
  justify-content: center;
}
@media (max-width: 1000px) {
  .footer-listing-alignment {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    flex-direction: column;
  }
  .footer-divider-container {
    padding-top: 50px !important;
  }
}

.data-heading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.002em;
  color: #1b1d1f;
}
.data {
  display: flex;
  align-items: center;
  font-family: "Nunito", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #6d737a;
  height: 35px;
  margin-top: 5px;
}
