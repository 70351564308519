.Container-top {
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: darkgrey;

  background-image: url("../assets/images/about.jpg");
  background-size: cover;
  background-position: center center;
}
.heading-aboutUs {
  width: 228px;
  height: 72px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 72px;
  /* identical to box height, or 138% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  color: #ffffff;
}
.first-image {
  width: 670px;
  height: 397px;
  /* margin-left: 375px; */
  margin-top: 60px;
  border-radius: 5px;
  background-image: url("../assets/images/about1.jpg");
  background-size: cover;
  background-position: center center;
}
.second-image {
  position: absolute;
  width: 670px;
  height: 317px;
  background: #ffffff;
  box-shadow: 1px 1px 31px 13px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  top: 100px;
  left: 490px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.heading-text {
  width: 129px;
  height: 72px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 72px;
  /* identical to box height, or 277% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  /* Black */

  color: #3e3e3e;
}
.Div-text {
  width: 595px;
  height: 192px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 32px;
  /* or 188% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Black */

  color: #3e3e3e;
}
.first-image1 {
  width: 670px;
  height: 397px;
  margin-left: 490px;
  margin-top: 143px;
  border-radius: 5px;
  background-image: url("../assets/images/about2.jpg");
  background-size: cover;
  background-position: center center;
}
.second-image2 {
  position: absolute;
  width: 670px;
  height: 317px;
  background: #ffffff;
  box-shadow: 1px 1px 31px 13px rgba(0, 0, 0, 0.04);
  border-radius: 5px;
  top: 183px;
  left: 0px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.heading-text1 {
  width: 145px;
  height: 72px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 72px;
  /* identical to box height, or 277% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  /* Black */

  color: #3e3e3e;
}
.Div-text1 {
  width: 595px;
  height: 224px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 32px;
  /* or 188% */

  display: flex;
  align-items: center;
  text-align: center;

  /* Black */

  color: #3e3e3e;
}
.mission-container {
  width: 1200px;
  position: relative;
}
.mission-alignment {
  display: flex;
  justify-content: center;
}
