.heading {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 72px;

  color: #ffffff;
}
.topContainer {
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/images/contactus.jpg");
  background-size: cover;
  background-position: center center;
}
.sub-heading {
  width: 786px;
  height: 134px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 44px;
  /* or 185% */

  display: flex;
  align-items: flex-end;
  text-align: center;

  /* Primary/50 */

  color: #edeff6;
}
.bottomContainer {
  width: 100%;
  height: 800px;
}
.bottomLeftContainer {
  top: 40px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.bottomRightContainer {
  top: 100px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header {
  width: 670px;
  display: flex;
  justify-content: flex-start;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 139.5%;
  bottom: 20px;

  color: #3e3e3e;
}
.formContainer {
  margin-top: 25px;
  width: 670px;
  height: 400px;
}
.form {
  border-radius: 5px;
  height: 70px;
  font-size: 20px;
}
.formButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px 63px;
  gap: 12.29px;
  background: #e5be54;
  border-radius: 9.83486px;
  width: 100%;
  height: 65px;
  font-size: 25px;
  color: white;
}
.top-text {
  width: 470px;
  height: 96px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 152%;
  /* or 24px */
  display: flex;
  align-items: center;

  /* Black */

  color: #3e3e3e;
}
.text-header {
  padding-top: 24px;
  width: 370px;
  height: 32px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 137.5%;
  /* identical to box height, or 32px */

  display: flex;
  align-items: center;

  /* Black */

  color: #3e3e3e;
}
.bottom-text {
  padding-top: 16px;
  width: 470px;
  height: 120px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 152%;
  /* or 24px */

  display: flex;
  align-items: center;

  /* Black */

  color: #3e3e3e;
}
.end-text {
  padding-top: 18px;
  width: 470px;
  height: 48px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 152%;
  /* or 24px */

  display: flex;
  align-items: center;

  /* Black */

  color: #3e3e3e;
}
