.home-section-1 {
  background: url("/public/icons/section1.png");
  width: 100%;
  height: 1050px;
  background-repeat: no-repeat;
  background-size: cover;
  object-fit: contain;
}
.home-header-container {
  position: fixed;
  margin-top: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.4px);
  -webkit-backdrop-filter: blur(5.4px);
  z-index: 1000;
}
.header-alignment {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 70px;
}
.home-logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.home-heading-container {
  padding-top: 315px;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: auto;
  color: white;
  margin-bottom: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 72px;
}
.col-alignment {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1000px) {
  .home-heading-container {
    padding-top: 150px;
    display: flex;
    justify-content: center;
    margin-top: auto;
    color: white;
    margin-bottom: auto;
    font-family: "Nunito";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 52px;
  }
  .home-paragraph-container p {
    font-family: "Nunito";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    color: #edeff6;
    width: 696px;
    height: 60px;
  }
  .home-search-row {
    padding: 10px !important;
  }
  .home-feature-card {
    padding: 30px !important;
  }
}
@media (max-width: 700px) {
  .home-heading-container {
    padding-top: 100px !important;
    font-size: 25px !important;
    line-height: 42px !important;
  }
  .home-paragraph-container p {
    font-size: 16px;
    width: 100% !important;
    height: auto !important;
  }
}
.hamMenu-fixed {
  position: fixed;
  margin-top: 81px;
  width: 100%;
  background: rgba(0, 0, 0, 0);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5.4px);
  -webkit-backdrop-filter: blur(5.4px);
  z-index: 1000;
}
.ham-alignment {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 50px;
  font-size: 18px;
  margin-left: 30px;
}
.home-paragraph-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.home-paragraph-container p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #edeff6;
  width: 696px;
  height: 60px;
}
.link-style {
  color: black;
}

.home-search-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-search-row {
  background-color: white;
  height: auto !important;
  border-radius: 15px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px;
  margin: 20px;
}
.search-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 156px;
  height: 50px;
  background: #e5be54;
  border-radius: 5.78475px;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 20px;
  text-align: center;
  color: #ffffff;
}
.logo-image {
  object-fit: contain;
  width: 150px;
  margin: 10px;
}
.ant-select-selector {
  height: 50px !important;
  width: 180px !important;
}
.ant-select-selection-placeholder {
  padding-top: 8px !important;
}
.hamburgerMenu {
  width: 50px;
}
.mobileView {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
}

/* property */

.property-heading-container {
  padding-top: 60px;
}
.property-heading-container p {
  margin: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #e5be54;
}
.property-paragraph-container {
  padding-top: 8px;
}
.property-paragraph-container p {
  margin: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: #3e3e3e;
}
.card {
  width: 370px;
  height: 450px;
  box-shadow: 0px 3.63636px 11.8182px 0.909091px rgba(0, 0, 0, 0.15);
  margin-top: 20px;
}
@media (max-width: 400px) {
  .card {
    width: 300px !important;
    height: 500px !important;
  }
}
.property-card-image-text-container {
  width: 100%;
  height: 250px;
  position: relative;
}
.property-card-image-text {
  width: 72px;
  height: 22px;
  background: #e5be54;
  border-radius: 2px;
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.property-card-image-text p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 10.9091px;
  text-align: center;
  color: #ffffff;
}
.card:hover {
  box-shadow: 3px 1.63636px 11px 2.8182px rgba(0, 0, 0, 0.15);
}
.card-state-description {
  margin-top: 18px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 16.3636px;
  line-height: 22px;
  margin-left: 22px;
  margin-right: auto;
  margin-bottom: auto;
  color: black;
}
.card-state-name {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 22.7273px;
  line-height: 31px;
  margin-left: 22px;
  margin-top: 19px;
  margin-bottom: auto;
  color: #e5be54;
}
.card-location-container {
  padding-top: 18px;
  padding-left: 22px;
  display: flex;
}
.card-location-container p {
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 12.7273px;
  line-height: 17px;
  text-align: center;
  color: #3e3e3e;
  margin-left: 9px;
}
.property-apartments-detail-container {
  display: flex;
  justify-content: space-between;
  padding-left: 22px;
  padding-right: 100px;
  padding-top: 26px;
}
.property-beds-container img {
  width: 16px;
  height: 13px;
}
.property-beds-container span {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 12.7273px;
  line-height: 17px;
  text-align: center;
  color: #3e3e3e;
  padding-left: 6px;
}
.property-view-all-button-conatainer {
  padding-top: 50px;
  padding-bottom: 70px;
  text-align: center;
}
.property-view-all-button-conatainer button {
  padding: 12px 20px;
  gap: 10px;
  width: 101px;
  height: 44px;
  background: #e5be54;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
}
/* feature */
.home-feature-container {
  height: auto;
  width: 100%;
  background: #0b2c3d;
}
.feature-heading-container {
  padding-top: 43px;
}
.feature-heading-container p {
  margin: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  text-align: center;
  color: #e5be54;
}
.feature-text-container {
  padding-top: 8px;
}
.feature-text-container p {
  margin: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;
  color: hsl(0, 0%, 100%);
}
.home-feature-card {
  display: flex;
  justify-content: center;
  padding: 60px;
}
.feature-card {
  width: 270px;
  height: 265px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: 30px;
}
.feature-card-icon-main-container {
  padding-top: 30px;
}
.feature-card-icon-container {
  width: 101.22px;
  height: 101.22px;
  border-radius: 50%;
  background: rgba(229, 190, 84, 0.2);
}
.feature-card-icon-container img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.feature-card-icon-main-container {
  display: flex;
  justify-content: center;
}
.feature-card-text-container {
  padding-top: 12px;
  display: flex;
  justify-content: center;
}
.feature-card-text-container-2 {
  padding-top: 12px;
  width: 205px;
  margin: 0 auto;
  height: 60px;
}
.feature-card-text-container b {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 14.4605px;
  line-height: 20px;
  text-align: center;
  color: #3e3e3e;
}
.feature-card-text-container-2 p {
  font-family: "Nunito";
  font-style: normal;
  align-items: center;
  margin: auto;
  font-weight: 300;
  font-size: 14.4605px;
  line-height: 20px;
  text-align: center;
  color: #3e3e3e;
}
.card-col {
  display: flex;
  justify-content: center;
}
.ant-carousel .slick-dots li button {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
}
.card-image {
  width: 100% !important;
  height: 250px !important;
  object-fit: cover;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

/* team */
.team-heading-container {
  padding-top: 60px;
}
.team-heading-container p {
  margin: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
  line-height: 33px;
  color: #e5be54;
}
.team-heading-container-2 {
  padding-top: 4px;
}
.team-heading-container-2 p {
  margin: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  text-align: center;

  color: #3e3e3e;
}
.team-heading-container-3 {
  padding-top: 8px;
  padding-bottom: 30px;
  width: 100%;
}
.team-heading-container-3 p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  margin: auto;
  color: #3e3e3e;
  padding-top: 8px;
  max-width: 594px;
}
.single-team-image-container {
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgba(229, 190, 84, 0.4) 0%,
    rgba(229, 190, 84, 0) 100%
  );
  border-radius: 15px;
}
.team-image-container {
  display: flex;
  justify-content: center;
}
.team-image-container img {
  height: 324px;
}
.team-designation-container {
  padding-top: 32px;
  display: flex;
  justify-content: center;
}
.team-designation-container p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 23px;
  line-height: 31px;
  color: #3e3e3e;
}

/* Home Contect Us */
.home-contect-us-container {
  padding-top: 86px;
}
.home-contect-us-heading-container p {
  margin: auto;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 50px;
  text-align: center;
  color: #3e3e3e;
}
.home-contect-us-paragraph-container {
  padding-top: 24px;
}
.home-contect-us-paragraph-container p {
  margin: auto;
  max-width: 508px;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  color: #3e3e3e;
}
.home-contect-us-button-container {
  display: flex;
  justify-content: center;
  padding-top: 32px;
}
.home-contect-us-button-container Button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 24px;
  gap: 10px;
  width: 138px;
  height: 56px;
  border: 1px solid #3e3e3e;
}
a {
  color: white;
  text-decoration: none;
}
.property-type {
  margin: 5px;
}
